<template>
<div>
  <b-container fluid class="bg-white my-4">
    <b-row>
      <b-col>
        <div class="mb-4" style="display: flex; height: 30px;">
          <filter-interface class="mr-4" namespace="tax" :rows="rows" v-model="rowsFiltered" :staticDataProperties="staticDataProperties" :includeTaxonomy="true"></filter-interface>
          <sort-interface class="mr-4" namespace="tax" :rows="rowsFiltered" v-model="rowsSorted" :optionsProperty="sortProperties"></sort-interface>
          <search-interface namespace="tax" :rows="rowsSorted" v-model="rowsSearched"></search-interface>
        </div>
        <b-pagination
          inline
          v-model="currentPage"
          :total-rows="rowsSearched.length"
          :per-page="perPage"
          class="mt-3"
        ></b-pagination>
        <div class="mb-3" v-for="item in showRowsPage" :key="item.id">
          <initiative-tax :item="item" :trim="400"></initiative-tax>
        </div>
        <b-pagination
          inline
          v-model="currentPage"
          :total-rows="rowsSearched.length"
          :per-page="perPage"
          class="mt-3"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import FilterInterface from '@/components/FilterInterfaceV4.vue'
import InitiativeTax from '@/components/InitiativeTax.vue'
import SearchInterface from '@/components/SearchInterfaceV1.vue'
import SortInterface from '@/components/SortInterfaceV1.vue'

export default {
  name: 'TaxHome',
  components: {
    FilterInterface,
    InitiativeTax,
    SearchInterface,
    SortInterface
  },
  computed: {
    rows: function () {
      return this.$store.state.initiativesTax
    },
    showRowsPage: function () {
      const start = (this.currentPage - 1) * this.perPage
      const end = this.currentPage * this.perPage
      return this.rowsSearched.slice(start, end)
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      perPage: 20,
      currentPage: 1,
      rowsFiltered: [],
      rowsSearched: [],
      rowsSorted: [],
      sortProperties: [
        { value: 'country', text: 'Country' },
        { value: 'created_at', text: 'Date created' },
        { value: 'updated_at', text: 'Date updated' },
        { value: 'name', text: 'Name' }
      ],
      staticDataProperties: [
        { name: 'Countries', property: 'country', direct: true },
        { name: 'Sectors', property: 'sectors', graph: 'sectors', sectors: true },
        { name: 'Status', property: 'impactstatus', graph: 'impactstatus', tree: true },
        { name: 'Workflow', property: 'workflow', direct: true }
      ]
    }
  },
  created: function () {
    document.title = "ESG Tax Dashboard"
  }
}
</script>

<style>
</style>
